



























import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  data: () => ({
    papers: [] as any,
    page: 1,
    totalPage: [] as any,
    numInOnePage: 20,
  }),
  mounted: function () {
    console.log("paper list mounted");
    axios
        .post(Vue.prototype.serverAddr + "/api/get-item-num", {
          collection: "achievement",
          need:{
            type:0
          }          
        })
        .then((response) => {
          console.log("get response");
          this.totalPage = Math.ceil((response.data as any).num / this.numInOnePage);
        });
    this.getPaperList();
  },
  watch: {
    "page": function (val, oldVal) {
      console.log("page" + oldVal +" to " + val);
      this.getPaperList();
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
  },
  methods: {
    getPaperList() {
      axios
        .post(Vue.prototype.serverAddr + "/api/get-item-list", {
          collection: "achievement",
          need:{
            type:0
          },
          page: this.page,
          numInOnePage: this.numInOnePage,
        })
        .then((response) => {
          console.log("get response");
          this.papers = response.data;
        });
    },
  },
});
